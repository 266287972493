<template>
	<div class="page-container">
		<div class="page-head">
			<div class="title">{{$t('liveplatform.list.title')}}</div>
		</div>
		<div class="page-action">
			<div>
				<el-button @click="$router.push({name: 'LiveCreate'});" type="livebtn" size="small" round>{{$t('liveplatform.list.createBtn')}}</el-button>
			</div>
		</div>
		<div class="page-list">
			<div class="item" v-for="(item,index) in pageList" :key="index">
				<div class="item-pic">
					<img :src="item.cover0" @error="imageLoadErrorLive" alt="" />
					<div class="item-status" v-if="item.is_disable==1">{{$t('liveplatform.list.banned')}}</div>
				</div>
				<div class="item-main">
					<div class="name">
						<el-tag v-if="item.status < 1" size="mini" effect="dark">{{$t('liveplatform.list.tagText1')}}</el-tag>
						<el-tag type="danger" v-else-if="item.status == 1" size="mini" effect="dark">{{$t('liveplatform.list.tagText2')}}</el-tag>
						<el-tag type="info" v-else-if="item.status == 2" size="mini" effect="dark">{{$t('liveplatform.list.tagText3')}}</el-tag>
						<span class="item-tit">{{item.title}}</span>
					</div>
					<div class="tags">
						<el-tag size="mini">{{item.screen=='vertical'?$t('liveplatform.list.vertical'):$t('liveplatform.list.horizontal')}}</el-tag>
						<el-tag size="mini">{{item.position?item.position:$t('liveplatform.marsText')}}</el-tag>
						<el-tag size="mini">{{item.tag_info.tag_name}}</el-tag>
						<el-tag size="mini">{{$t('liveplatform.list.visitor')}}: {{item.views}}</el-tag>
					</div>
					<div class="text">
						<span>{{$t('liveplatform.list.settime')}} {{item.settime}}</span>
						<span>{{$t('liveplatform.list.liveID')}}: {{item.live_id}}</span>
					</div>
				</div>
				<div class="item-action">
					<ul>
						<li v-if="item.status < 0"><span class="expire">{{$t('liveplatform.list.expired')}}</span></li>
						<li>
							<el-popover
								placement="top"
								width="160"
								trigger="hover"
								popper-class="live-popover">
								<ul class="popover-qrcode" v-if="item.status == 2">
									<li><vue-qr :text="$itemBasePath+'/m/uulive/playback/'+item.live_id"></vue-qr></li>
									<li><el-button @click="handleCopy('playback',item.live_id)" size="mini" round>{{$t('liveplatform.list.copyBtn')}}</el-button></li>
								</ul>
								<ul class="popover-qrcode" v-else-if="item.status == 1">
									<li><vue-qr :text="$itemBasePath+'/m/uulive/info/'+item.live_id"></vue-qr></li>
									<li><el-button @click="handleCopy('info',item.live_id)" size="mini" round>{{$t('liveplatform.list.copyBtn')}}</el-button></li>
								</ul>
								<ul class="popover-qrcode" v-else>
									<li><vue-qr :text="$itemBasePath+'/m/uulive/edit/'+item.live_id"></vue-qr></li>
									<li><el-button @click="handleCopy('edit',item.live_id)" size="mini" round>{{$t('liveplatform.list.copyBtn')}}</el-button></li>
								</ul>
								<el-button slot="reference" size="mini" round>{{$t('liveplatform.list.shareBtn')}}</el-button>
							</el-popover>
						</li>
						<li v-if="item.status !==1"><el-button @click="handleDeleteLive(item.live_id)" size="mini" round>{{$t('liveplatform.list.deleteBtn')}}</el-button></li>
						<template v-if="item.status == 0">
							<li><el-button @click="$router.push({name: 'LiveEdit',params:{id:item.live_id}})" size="mini" round>{{$t('liveplatform.list.infoBtn')}}</el-button></li>
							<li><el-button @click="handleBeginLive(item.live_id)" type="danger" size="mini" round>{{$t('liveplatform.list.openBtn')}}</el-button></li>
						</template>
						<template v-if="item.status == 1">
							<!-- <li><el-button size="mini" round>{{$t('liveplatform.list.spreadBtn')}}</el-button></li> -->
							<li><el-button @click="$router.push({name: 'LiveInfo',params:{id:item.live_id}})" type="danger" size="mini" round>{{$t('liveplatform.list.infoBtn')}}</el-button></li>
						</template>
						<template v-if="item.status == 2">
							<li><el-button @click="$router.push({name:'LivePlayback',params:{id:item.live_id}})" size="mini" round>{{$t('liveplatform.list.playbackBtn')}}</el-button></li>
							<!-- <li><el-button size="mini" round>{{$t('liveplatform.list.dataBtn')}}</el-button></li> -->
						</template>
					</ul>
				</div>
			</div>
			<div class="page-foot">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="pageCurr"
					:page-size="pageSize"
					layout="total, prev, pager, next"
					:prev-text="$t('liveplatform.pagination.prevText')"
					:next-text="$t('liveplatform.pagination.nextText')"
					:total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import VueQr from 'vue-qr';
	import { getLiveList, onBroadcast, copyShareCode, deleteLive } from '@/api/liveplatform';
	export default {
		name: 'liveList',
		components: {
			VueQr,
		},
		data() {
			return {
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
			}
		},
		created() {
			this.getPageList();
		},
		methods: {
			imageLoadErrorLive(e) {
				e.target.src = this.$imageErrorLive;
			},
			getPageList(){
				let loading = this.$loading();
				let param = {
          page_index:	(this.pageCurr-1)*this.pageSize,
          page_size: this.pageSize,
					app_key: this.$store.getters.appKey
				}
				getLiveList(param).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.total);
					loading.close();
				}).catch(() => {
					loading.close();
				})
			},
			handleSizeChange(val){
				this.pageSize = val;
			},
			handleCurrentChange(val){
				this.pageCurr = val;
				this.getPageList();
			},
			handleBeginLive(id){
				this.$confirm(this.$t('liveplatform.confirmMsg.playText'), this.$t('liveplatform.confirmMsg.title'), {
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let param = {
						liveId: id,
						uuid: this.$store.getters.uuid,
						app_key: this.$store.getters.appKey
					}
					onBroadcast(param).then(() => {
						this.$confirm(this.$t('liveplatform.confirmMsg.playSuccessText'),this.$t('liveplatform.confirmMsg.playSuccessTitle'),{
							confirmButtonText: this.$t('liveplatform.confirmMsg.confirmJoinBtn'),
							cancelButtonText: this.$t('liveplatform.confirmMsg.cancelDownBtn'),
						}).then(() => {
							this.$router.push({ name: 'LiveInfo', params: { id: id }});
						}).catch(() => {
							window.open(this.$liveBasePath+'/upload');
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('liveplatform.cancelMsg.play')
					});          
				});
			},
			handleDeleteLive(id){
				this.$confirm(this.$t('liveplatform.confirmMsg.liveDeleteText'), this.$t('liveplatform.confirmMsg.title'), {
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let param = {
						liveId: id,
						app_key: this.$store.getters.appKey
					}
					deleteLive(param).then(() => {
						this.$message.success(this.$t('liveplatform.successMsg.delete'));
						this.getPageList()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('liveplatform.cancelMsg.delete')
					});          
				});
			},
			handleCopy(type,id){
				let param = {
					live_id : id,
					app_key: this.$store.getters.appKey
				}
				copyShareCode(param).then(() => {
					let text = this.$itemBasePath+'/m/uulive/'+type+'/'+id;
					let inputObj = document.createElement('input');
					inputObj.value = text;
					document.body.appendChild(inputObj);
					inputObj.select();
					document.execCommand("Copy");
					this.$message.success(this.$t('liveplatform.successMsg.copy'));
					inputObj.remove();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-container {
		border-radius: 6px;
		padding: 20px;
		background-color: $--live-background-default;
		.page-head {
			color: #FFFFFF;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 20px;
		}
		.page-action {
			display: flex;
			justify-content: flex-end;
			::v-deep .el-button--livebtn {
				color: #FFFFFF;
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:focus,&:hover {
					color: #FFFFFF;
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}
		}
		.page-list {
			.item {
				padding: 10px 20px;
				display: flex;
				align-items: center;
				.item-pic {
					width: 70px;
					height: 70px;
					border-radius: 6px;
					margin-right: 15px;
					overflow: hidden;
					position: relative;
					border: 1px solid rgba(0,0,0,.05);
					box-sizing: border-box;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.item-status {
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						font-size: 16px;
						color: $--live-primary-black-32;
						background-color: rgba(0,0,0,.4);
					}
				}
				.item-main {
					flex: 1;
					width: 0;
					.name {
						line-height: 20px;
						.el-tag {
							width: 56px;
							color: #FFFFFF;
							text-align: center;
							border-radius: 10px;
							line-height: 20px;
							border: none;
							background: $--live-tag-dark-shading;
						}
						.el-tag--info {
							background: $--live-tag-info-shading;
						}
						.el-tag--danger {
							background: $--live-tag-danger-shading;
						}
						.item-tit {
							color: #FFFFFF;
							font-size: 16px;
							margin-left: 15px;
						}
					}
					.tags {
						margin: 6px 0;
						::v-deep .el-tag {
							color: #FFFFFF;
							padding: 0 7px;
							margin-right: 8px;
							border-radius: 6px;
							border-color: $--live-tag-info;
							background-color: $--live-tag-info;
						}
					}
					.text {
						color: $--live-text-color-2;
						font-size: 12px;
						line-height: 20px;
						span {
							margin-right: 8px;
						}
					}
				}
				.item-action {
					li {
						display: inline-block;
						margin-left: 10px;
						.expire {
							color: $--live-link-color-2;
						}
						.el-button {
							color: $--live-text-color-2;
							border-color: $--live-gray-white-12;
							background-color: transparent;
							&:hover {
								color: $--live-button-primary-hover-font;
								border-color: $--live-button-primary-hover-border;
							}
						}
						.el-button--danger {
							color: #FFFFFF;
							border-color: $--live-button-primary-border;
							background-color: $--live-button-primary-background;
							&:focus,&:hover {
								color: #FFFFFF;
								border-color: $--live-button-primary-hover-border;
								background-color: $--live-button-primary-hover-background;
							}
						}
					}
				}
			}
		}
		.page-foot {
			padding: 10px 0;
			text-align: center;
			::v-deep .el-pagination {
				color: $--live-text-color-2;
				.el-pagination__total {
					color: $--live-gray-white-20;
				}
				.btn-next,.btn-prev {
					color: $--live-text-color-2;
					background-color: transparent;
				}
				.btn-next:hover,.btn-prev:hover {
					color: $--live-link-hover-1;
				}
				button:disabled {
					color: $--live-gray-white-20!important;
				}
				.el-pager {
					li {
						background-color: transparent;
						&:hover,&.active {
							color: $--live-link-hover-1;
						}
						&.btn-quicknext {
							color: $--live-text-color-2;
						}
					}
				}
			}
		}
	}
	.popover-qrcode {
		text-align: center;
		li {
			margin-top: 10px;
		}
		li:first-child {
			margin-top: 0;
		}
		img {
			width: 160px;
			height: 160px;
		}
		::v-deep .el-button {
			color: $--live-text-color-2;
			background-color: transparent;
			border-color: $--live-gray-white-12;
		}
		::v-deep .el-button:hover {
			color: $--live-button-primary-hover-font;
			border-color: $--live-button-primary-hover-border;
		}
	}
</style>
